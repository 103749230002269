import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import parse from 'html-react-parser';
import { URL_LIST_ALL } from "../../configs/Api";
import AkkhorLayout from "../../layout/Akkhor";
import ViewQuestionRacing from "../questions/ViewQuestionRacing";
import GenderCode from "../../component/GenderCode";
import men_teacher from "../../assets/img/men-teacher.png";
import girl_teacher from "../../assets/img/girl-teacher.png";

export default function RacingDetailView(props) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [detail, setDetail] = useState(null);
  const [answers, setAnswers] = useState(null);
  const { followtest } = useSelector((state) => state.save);

  useEffect(() => {
    if (id > 0) {
      let mounted = true;
      Axios.get(URL_LIST_ALL + "at_exam_students/" + id, user.config).then(async (res) => {
        if (res.data.status === "success" && mounted) {
          const _detail = res.data.data;
          setDetail(_detail);
          let info = _detail.info
          setStudentAnswer(info)
        } else console.log(res.data.message);
      }).catch((err) => console.log(err));
      return () => (mounted = false);
    }
  }, [user, id]);

  const setStudentAnswer = (info) => {
    let listCorrect = [];
    info.forEach((item) => {
      let _corrects = item.corrects;
      if (item.code === "QMatching" && _corrects && _corrects !== "null") {
        let _x = [];
        _corrects.map((item) => {
          _x[item.right] = item.label;
          return item;
        });
        _corrects.corrects = _x;
      }
      if (item.code === "QMatching")
      item.corrects = _corrects;
      listCorrect.push(item);
    });
    setAnswers(listCorrect);
  }

  useEffect(() => {
    if (id && followtest && followtest.data && followtest.data.exam_student == id) {
      if (followtest.data.data_answer) {
        let newAnswer = [...answers]
        let listCorrect = [];
        newAnswer.map((item) => {
          if (followtest.data.data_answer.question_id == item.question_id) {
            item.student_answer.corrects = followtest.data.data_answer.corrects
            item.student_answer.point = followtest.data.data_answer.point
            item.corrects = followtest.data.data_answer.corrects
            item.point = followtest.data.data_answer.point
            if(item.code == "QFillTo"){
              item.corrects = JSON.parse(followtest.data.data_answer.corrects)
            }
          }
          listCorrect.push(item);
        })
        setAnswers(listCorrect);
      }
      window.location.href = '/examination-view/' + id + '#nuques-' + followtest.data.exam_student_ans;
    }
  }, [followtest, id]);

  return (
    <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
      {(detail && answers) ? (
        <div className="container-fluid">
          <div className="breadcrumbs-area py-3">
            <ul>
              <li>
                <Link to="/">Trang chủ</Link>
              </li>
              <li>{detail && detail.type === 2 && "Bài kiểm tra"} {detail && detail.type === 3 && "Phiếu bài tập"}</li>
            </ul>
          </div>
          <div className="card height-auto">
            <div className="card-body">
              <div>
                <h2 className="text-center mb-3">{detail.title}</h2>
                <div className="row mb-2">
                <div className="col-12 col-sm-2"></div>
                  <div className="col-12 col-sm-8">
                    <table className="table table-bordered">
                      <tr>
                        <td>Họ và tên:</td>
                        <td>{detail && detail.student_name}</td>
                      </tr>
                      <tr>
                        <td>Mã học sinh:</td>
                        <td>{detail && detail.student_code}</td>
                      </tr>
                      <tr>
                        <td>Lớp:</td>
                        <td>{detail && detail.class_name}</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-12 "></div>
                  <div className="col-12 col-sm-3 d-flex">
                    <div className="text-primary" style={{ fontSize: 50 }}><i className="fas fa-medal"></i></div>
                    <div className="py-1 px-2">
                      <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.totalRightAnswer}/{detail.total_number}</div>
                      <div>Số câu trả lời đúng</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-2 d-flex">
                    <div className="text-success" style={{ fontSize: 50 }}><i className="fas fa-clipboard-list"></i></div>
                    <div className="py-1 px-2">
                      <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.point}</div>
                      <div>Điểm đạt được</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-2 d-flex">
                    <div className="text-info" style={{ fontSize: 50 }}><i className="far fa-clock"></i></div>
                    <div className="py-1 px-2">
                      <div style={{ fontSize: 25, fontWeight: 'bold' }}>{detail.time} phút</div>
                      <div>Thời gian làm bài</div>
                    </div>
                  </div>
                  {detail && detail.comments &&
                    <div className="col-sm-5 d-flex">
                      <div className="group-auth">
                        {detail.author_info &&
                          <div className="avatar-gr text-center pr-1">
                            <img src={detail.author_info.gender === 1 ? men_teacher : girl_teacher} alt="teacher" />
                          </div>
                        }
                      </div>
                      <div className="w-100">
                        {detail.author_info &&
                          <div className="w-100">
                            <p><strong>GV. {detail.author_info.fullname}</strong> <span className="t-name">đã nhận xét</span></p>
                            <p className="p-2 border rounded bg-light">{detail.comments}</p>
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
               
                {answers && Object.values(answers).map((ans, index) => (
                  <div key={index} className={"mb-2 border rounded overflow-hidden " + (ans.student_answer && ans.student_answer.status && ans.student_answer.status === 2 ? "answer-none" : (ans.id && ans.point > 0 ? "answer-true" : ""))} id={"nuques-" + ans.student_answer.id}>
                    <div className="racing-view-test">
                      <div className={"font-weight-bold p-2 " + (ans.student_answer.point == null ? 'bg-secondary' : (ans.id && ans.student_answer.point > 0 ? 'bg-success' : 'bg-danger')) + " text-light d-flex justify-content-between"}>
                        <div>Câu hỏi {index + 1}</div>
                        <div className="">
                          {ans.student_answer.point && ans.student_answer.point > 0 ? <> {ans.student_answer.point} điểm </> : <> 0 điểm </>}
                        </div>
                      </div>
                      {ans.parent_title && <div className="p-2 border-bottom"><GenderCode text_string={ans.title} /></div>}
                      <div className="p-3 border-bottom mb-3">
                        <GenderCode text_string={ans.title} />
                      </div>
                      <div className="">
                        <ViewQuestionRacing item={ans}></ViewQuestionRacing>
                      </div>
                      {ans.student_answer.comments &&
                        <div className="comment border-top p-2 mt-3">
                          <span>Nhận xét</span>
                          <div className="w-100 mt-2 d-flex">
                          <p className="p-2 border rounded bg-light">{parse(ans.student_answer.comments + '')}</p>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="breadcrumbs-area py-3">
          <ul>
            <li>
              <Link to="/">Trang chủ</Link>
            </li>
          </ul>
        </div>
      )}
    </AkkhorLayout>
  );
}
