import React, { useEffect, useState } from "react";
import { URL_LIST_ALL } from "../configs/Api";
import Axios from "axios";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Spinner from "../component/Spinner";
import avatar_student from "../assets/img/avata_student.png"
import men_teacher from "../assets/img/men-teacher.png"
import girl_teacher from "../assets/img/girl-teacher.png"
import Moment from "react-moment";

export default function FeedBackComment(props) {
    const { question_id, test_id, exam_student } = props;
    const { user } = useSelector((state) => state.auth)
    const { socket } = useSelector((state) => state.save);
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [listComment, setListComment] = useState([])
    const [contentComment, setContentComment] = useState('')

    const sendComment = () => {
        if (contentComment && contentComment !== '') {
            setLoading(true)
            let datasend = { comments: contentComment, user_id: user.data.id, question_id: question_id, test_id: test_id, exam_student_id: exam_student.id }
            Axios.post(URL_LIST_ALL + "at_exam_students_comment/", datasend, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    confirmAlert({
                        title: 'Thành công',
                        message: 'Gửi phản hồi thành công',
                        buttons: [{ label: 'OK' }]
                    });
                    setRefresh(!refresh)
                    setContentComment('')
                } else {
                    alert(res.data.message)
                }
                setLoading(false)
            }).catch((err) => { console.log(err); setLoading(false) });
        }
    }
    useEffect(() => {
        if (exam_student && user) {
            Axios.get(URL_LIST_ALL + "at_exam_students_comment?exam_student_id=" + exam_student.id + '&question_id=' + question_id + '&test_id=' + test_id, user.config).then(async (res) => {
                if (res.data.status === 'success') {
                    let dats = res.data.data
                    dats.reverse()
                    setListComment(dats)
                } else {
                    alert(res.data.message)
                }
            }).catch((err) => { console.log(err); setLoading(false) });
        }
    }, [user, exam_student,question_id,test_id,refresh,socket])
    return (
        <div className="comment-group">
            <div className="wrapper">
                <p>Bình luận</p>
                <div className="w-100 d-flex">
                    {listComment && <div className="list-comments">
                        {listComment.map((item, i) => {
                            return (<div className="item-comments d-flex mb-2" key={i}>
                                <div className="user-info">
                                    <div className="avatar">
                                        {item.typeaccount === 2 ? <img src={avatar_student} alt="" /> :
                                            <img src={item.gender === 1 ? men_teacher : girl_teacher} alt="" />
                                        }
                                    </div>
                                </div>
                                <div className="content-comment">
                                    <div className="fullname">
                                        {item.fullname}
                                    </div>
                                    <div className="comment-text">
                                        {item.comments}
                                    </div>
                                    <div className="comment-date">
                                        <Moment format="HH:mm DD/MM/YYYY">{new Date(item.datecreate * 1000)}</Moment>
                                    </div>
                                </div>
                            </div>)
                        })}
                    </div>}
                </div>

                <div className="comment-form d-flex mt-2">
                    <input type="text" placeholder="Viết bình luận..." className="form-control" onChange={(e) => setContentComment(e.target.value)} value={contentComment} />
                    {loading ? <Spinner /> : <button className="btn btn-sender" onClick={() => { sendComment() }}> <i className="fas fa-arrow-circle-right"></i></button>}
                </div>
            </div>
        </div>
    );
}
