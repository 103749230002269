export const TYPE_ACCOUNT = [
  "Quản trị viên",
  "Giáo viên",
  "Học sinh",
  "phụ huynh"
];
export const ID_TYPE_ACCOUNT = {
  QUAN_TRI: 0,
  GIAO_VIEN: 1,
  HOC_SINH: 2,
  PHU_HUYNH: 3,
}
export const LIST_TABLE = [
  "schools",
  "grades",
  "class",
  "students",
  "subjects",
  "timetable",
  "users",
  "years",
];
export const LIST_ALPHA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const LIST_NATION = [
  "Kinh",
  "Tày",
  "Thái",
  "Mường ",
  "Khmer",
  "Hoa ",
  "Nùng  ",
  "Mông",
  "Dao",
  "Gia Rai",
  "Ê  Đê",
  "Ba Na",
  "Sán Chay ",
  "Chăm ",
  "Cơ Ho",
  "Xơ Đăng",
  "Sán Dìu",
  "Hrê",
  "RaGlay",
  "Mnông",
  "Thổ",
  "Xtiêng",
  "Khơ mú",
  "Bru Vân Kiều",
  "Cơ Tu",
  "Giáy",
  "Tà Ôi",
  "Mạ",
  "Giẻ-Triêng",
  "Co",
  "Chơ Ro",
  "Xinh Mun",
  "Hà Nhì",
  "Chu Ru",
  "Lào",
  "La Chí",
  "Kháng",
  "Phù Lá",
  "La Hủ",
  "La Ha",
  "Pà Thẻn",
  "Lự",
  "Ngái",
  "Chứt",
  "Lô Lô",
  "Mảng",
  "Cơ Lao",
  "Bố Y",
  "Cống",
  "Si La",
  "Pu Péo",
  "Rơ Măm",
  "Brâu",
  "Ơ Đu",
];
export const COLORS_CHECK = [
  "#999",
  "#21c83d",
  "#efd732",
  "#fd7a23",
  "#db0d18",
];

export const RolesRight = {
  "exam_question": {
    title: "Ngân hàng câu hỏi",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-param": {
    title: "Thông số khảo thí",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  tree: { title: "Cây kiến thức", checked: false },
  'examination-report': { title: "Thống kê phiếu bài tập và bài kiểm tra", checked: false },
  "marktests": { title: "Chấm bài kiểm tra", checked: false },
  "teachers-manager": {
    title: "Phân công tổ trưởng",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-tests-azota": {
    title: "Bài kiểm tra kiểu azota",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "tao-qrcode": {
    title: "Tạo mã QRcode",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  dashboard: { title: "Bảng điều khiển", checked: false },
  timetable: {
    title: "Thời khoá biểu",
    children: {
      edit: { title: "Dạy thay", checked: false },
      change: { title: "Đổi lịch", checked: false },
    },
    checked: false,
  },
  teaching: {
    title: "Lịch báo giảng",
    children: {
      all: { title: "Xem tất cả", checked: false },
      group: { title: "Theo bộ môn", checked: false },
    },
    checked: false,
  },
  lunch: {
    title: "Điểm danh ăn trưa",
    checked: false,
    children: {
      all: { title: "Tất cả", checked: false },
    },
  },
  attends: {
    title: "Điểm danh học sinh",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
      group: { title: "Theo bộ môn", checked: false },
    },
  },
  sleep: {
    title: "Điểm danh ngủ trưa",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
    },
  },
  extrac: {
    title: "Điểm danh thể thao",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
    },
  },
  special: {
    title: "Điểm danh lớp chuyên",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
    },
  },
  bus: {
    title: "Điểm danh xe buýt",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
    },
  },
  bus_manager: {
    title: "Quản lý xe buýt",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
      add: { title: "Thêm mới", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xóa", checked: false },
    },
  },
  examofnotebook: {
    title: "Kiểm tra vở ghi chép",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
      group: { title: "Theo bộ môn", checked: false },
    },
  },
  examofexercise: {
    title: "Kiểm tra bài tập",
    checked: false,
    children: {
      all: { title: "Xem tất cả", checked: false },
      group: { title: "Theo bộ môn", checked: false },
    },
  },
  "list-students": {
    title: "Quản lý học sinh",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      changeclass: { title: "Đổi lớp", checked: false },
    },
  },
  "list-teachers": {
    title: "Quản lý giáo viên",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-class": {
    title: "Quản lý lớp học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-books": {
    title: "Quản lý chương trình học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-books-tree": {
    title: "Cây kiến thức cho CT học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-books-page": {
    title: "Quản lý các trang cho CT học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-extrac": {
    title: "Quản lý ngoại khoá",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-group-extrac": {
    title: "Quản lý nhóm ngoại khoá",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-subjects": {
    title: "Quản lý môn học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-years": {
    title: "Quản lý năm học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-schools": {
    title: "Quản lý trường học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "khoa-hoc": {
    title: "Quản lý khóa học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      baihoc_manager: { title: "Quản lý các bài học", checked: false },
      manhinh_manager: { title: "Quản lý các màn hình", checked: false },
      manhinh_item: { title: "Quản lý các item màn hình", checked: false },
    },
  },
  "list-parts": {
    title: "Quản lý bộ phận",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      member: { title: "Nhân sự", checked: false },
    },
  },
  "list-users": {
    title: "Quản lý tài khoản",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      reset: { title: "Đặt mật khẩu", checked: false },
      parts: { title: "Bộ phận", checked: false },
      login: { title: "Đăng nhập", checked: false },
    },
  },
  "list-roles": {
    title: "Quản lý quyền hạn",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-exercise": {
    title: "Quản lý phiếu bài tập",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-tomo-racing": {
    title: "Quản lý Đường đua Tomo",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-books-exercise": {
    title: "Quản lý Bài tập cho sách",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-tests": {
    title: "Quản lý bài kiểm tra",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  examination: {
    title: "Khảo thí",
    checked: false,
  },
  
  "internal": {
    title: "Nội bộ",
    checked: false,
    children: {
      'history-action-user': { title: "Lịch sử thao tác của mình", checked: false },
      'history-action': { title: "Lịch sử thao tác", checked: false },
      'history-login': { title: "Lịch sử đăng nhập", checked: false },
      'registry-lunch': { title: "Đăng ký ăn trưa", checked: false },
    },
  },
  "report": {
    title: "Báo cáo",
    checked: false,
    children: {
      'report-attends': { title: "Báo cáo điểm danh học sinh", checked: false },
      'report-attends-month': { title: "Báo cáo đi học tháng", checked: false },
      "report-timekeeping": { title: "Báo cáo giáo viên điểm danh", checked: false, },
      'report-lunch': { title: "Báo cáo đăng ký ăn trưa", checked: false },
      'report-lunch-month': { title: "Báo cáo đăng ký ăn trưa tháng", checked: false },
      'report-check': { title: "Báo cáo chuyên cần", checked: false },
      'report-diary': { title: "Nhật ký lớp học", checked: false },
      'report-activity': { title: "Hoạt động giáo viên", checked: false },
      'report-allschool': { title: "Báo cáo toàn trường", checked: false },
    },
  },
  "setting": {
    title: "Cài đặt",
    checked: false,
    children: {
      "general-setting": { title: "Cài đặt chung", checked: false },
      "create-timetable": { title: "Tạo thời khoá biểu", checked: false },
      "import-data": { title: "Nhập dữ liệu", checked: false },
    },
  },

  "list-tags": {
    title: "Quản lý thẻ cho môn học",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-danhmuc": {
    title: "Quản lý danh mục đường đua",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },

};


export const CONFIG_EDITOR = {

  toolbar: {
    items: [
      'heading', 'MathType', 'ChemType',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'mediaEmbed',
      'insertTable',
      'blockQuote',
      'undo',
      'redo'
    ]
  },
}
export const CONFIG_STEP_TEST_1 = ['Thông tin bài thi', 'Lựa chọn câu hỏi', 'Chọn học sinh']
export const CONFIG_STEP_TEST_2 = ['Thông tin đề kiểm tra', 'Lựa chọn câu hỏi', 'Phát đề kiểm tra']
