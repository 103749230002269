import Axios from "axios";
import React, { useEffect, useState } from "react";
import AkkhorLayout from '../layout/Akkhor';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import { URL_LIST_ALL } from "../configs/Api";
import SingleChoiceQuestion from "../examination/questions/SingleChoiceQuestion";
import parse from 'html-react-parser'
import GenderCode from "../component/GenderCode";
import FeedBackComment from "../component/FeedBackComment";
import { useLocation, useParams, Redirect } from "react-router-dom";
import Spinner from "../component/Spinner";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function RacingDetail(props) {
    let query = useQuery();
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);
    const paramStudentId = parseInt(query.get("student_id"));
    const questionID = parseInt(query.get("question_id"));

    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [listQuestion, setListQuestion] = useState([]);
    const [indexQuestion, setIndexQuestion] = useState(0);
    const [onAnswer, setOnAnswer] = useState(null);
    const [checkChoice, setCheckChoice] = useState(false);
    const [textStatus, setTextStatus] = useState('');
    const [finish, setFinish] = useState(false);
    const { Answers } = useSelector(state => state.save);
    const [examStudent, setExamStudent] = useState(null)
    const [totalPoint, setTotalPoint] = useState(0)
    const [resetPoint, setResetPoint] = useState(false)

    const [checking, setChecking] = useState(false);  // Trạng thái kiểm tra câu trả lời


    useEffect(() => {
        if (id > 0 && user) {
            let mounted = true;
            let student_id = paramStudentId ? paramStudentId : user.data.id
            Axios.get(URL_LIST_ALL + "get_tomoracing?category_id=" + id + "&student_id=" + student_id, user.config).then(async (res) => {
                if (res.data.status === "success" && mounted) {
                    let data_rs = res.data
                    if (data_rs) {
                        if (data_rs.data && data_rs.data.length > 0) {
                            setListQuestion(data_rs.data);
                            data_rs.test_info.total = data_rs.total
                            setDetail(data_rs)
                            let exam_student_test = data_rs.data.at_exam_students
                            if (data_rs.rc_status == 0) {
                                let check = 1
                                data_rs.data.forEach((item, i) => {
                                    if (item.answer_status != 1 && check == 1) {
                                        setIndexQuestion(i);
                                        setExamStudent(item.at_exam_students)
                                        check = 0
                                    }
                                });

                                if (questionID) {
                                    data_rs.data.forEach((item, i) => {
                                        if (item.id == questionID) {
                                            setIndexQuestion(i);
                                        }
                                    });
                                }
                                setExamStudent(exam_student_test)

                            } else {
                                setFinish(true)
                            }

                        } else {
                            setDetail(data_rs)
                        }
                    }
                }
                else console.log(res.data.message);
            }).catch((err) => console.log(err));

            return () => (mounted = false);
        }
    }, [user, id, refresh]);// eslint-disable-line

    useEffect(() => {
        if (id > 0 && user) {
            Axios.get(URL_LIST_ALL + 'get_point_racing/' + id, user.config).then((res) => {
                setTotalPoint(res.data.data.point)
            })
        }
    }, [id, resetPoint])

    const checkAnswer = async (question, answer) => {
        setChecking(true);
        const apikey = 'sk-proj-ylhclsRfpkBiUIV2HjDeT3BlbkFJCVQ3O30fmvCoGDwpmzN8';
        try {
            const response = await Axios.post('https://api.openai.com/v1/chat/completions', {
                model: "gpt-4o-mini",
                messages: [
                    { role: "system", content: "You are a Python code grader. You will receive a question and a student's answer. You should return whether the answer is correct or incorrect in JSON format and in Vietnamese. Explain in detail if the student's answer is not correct." },
                    { role: "user", content: `Question: ${question}\n\nStudent's Answer: ${answer}\n\nPlease check the answer and return the result as follows: { "correct": true/false, "explanation": "explanation" }` }
                ],
                max_tokens: 500
            }, {
                headers: {
                    'Authorization': `Bearer ${apikey}`,
                    'Content-Type': 'application/json'
                }
            });
            let result = response.data.choices[0].message.content;
            if (result.includes('```json\n{')) {
                result = result.replace('```json\n{', '{')
            }
            if (result.includes('}\n```')) {
                result = result.replace('}\n```', '}')
            }
            try {
                setChecking(false)
                result = JSON.parse(result)
            } catch (parseError) {
                setChecking(false)
                console.error("Error parsing JSON:", parseError);
            }
            return result;
        } catch (error) {
            console.error("Error checking answer:", error);
            setChecking(false);
            return false;
        }

    };


    const checkQuestion = async (item_answer, answers) => {
        let s_answers = answers ? answers : (Answers && Answers[item_answer.id])
        if (s_answers) {
            let ans_true = true
            let student_answers = s_answers.answers
            let question_answers = s_answers.question.corrects

            if (!Array.isArray(question_answers)) {
                question_answers = JSON.parse(question_answers)
            }
            let snschoice = []
            let array_alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
            let text_ans = ''

            if (s_answers.question.code === "QFillTo") {
                question_answers.forEach((item, i) => {
                    try {
                        if (item && typeof item == 'string' && item.includes("||")) {
                            const arr = item.split('||').map(string => {
                                if (string) string.toString().trim()
                            })
                            if (typeof item == 'string' && student_answers[i].trim() && arr.includes(student_answers[i].trim())) {
                                ans_true = true
                                snschoice.push(1)
                            } else {
                                ans_true = false
                                snschoice.push(0)
                            }
                        } else {
                            if (item == student_answers[i]) {/* eslint eqeqeq: 0 */
                                ans_true = true
                                snschoice.push(1)
                            } else if (typeof item == 'string' && item == student_answers[i].trim()) {/* eslint eqeqeq: 0 */
                                ans_true = true
                                snschoice.push(1)
                            } else if (typeof item == 'string' && item.trim() == student_answers[i].trim()) {/* eslint eqeqeq: 0 */
                                ans_true = true
                                snschoice.push(1)
                            } else {
                                ans_true = false
                                snschoice.push(0)
                            }
                        }
                    } catch (error) {
                        ans_true = false
                        snschoice.push(0)
                    }

                });

                text_ans = question_answers.join("; ")
                if (typeof text_ans == 'string' && text_ans.includes("||")) {
                    text_ans = text_ans.replace('||', ' hoặc ')
                }
                text_ans = question_answers.map((itQ, i) => {
                    return (<div className="col-6 d-flex mt-1 d-flex align-items-center" key={i}>
                        <span className="text-number d-inline-block mr-2">{i + 1}</span> <span className="w-100 p-1 border rounded d-inline-block border-secondary bg-white">{typeof itQ == 'string' && itQ.includes("||") ? itQ.replace('||', ' hoặc ') : itQ}</span>
                    </div>)
                })
            }
            if (s_answers.question.code === "QChooseOne") {
                if (student_answers === question_answers) {
                    ans_true = true
                    snschoice.push(1)
                } else {
                    ans_true = false
                    snschoice.push(0)
                }
                let answ = Array.isArray(s_answers.question.answers) ? s_answers.question.answers : JSON.parse(s_answers.question.answers)
                text_ans = <div>{array_alpha[question_answers]}. <button type="button" className="btn btn-sm btn-secondary"><GenderCode text_string={answ[question_answers]} /></button></div>
            }
            if (s_answers.question.code === "QChooseMulti") {
                ans_true = true
                Object.keys(student_answers).forEach((item, i) => {
                    if (question_answers[i] != item) { /* eslint eqeqeq: 0 */
                        ans_true = false
                    }
                });
                snschoice.push(ans_true ? 1 : 0)
            }

            if (s_answers.question.code === "QDropDrag") {
                ans_true = true
                student_answers.forEach((itA, idx) => {
                    if (itA !== question_answers[idx]) {
                        ans_true = false
                    }
                });
                snschoice.push(ans_true ? 1 : 0)
            }

            let point = 0
            if (ans_true) point = item_answer.question_point
            if (s_answers.question.code === "QCode") {
                const response = await checkAnswer(s_answers.question.title, student_answers);  // Gọi API để kiểm tra câu trả lời và chờ kết quả
                if (response) {
                    try {
                        let decode = response
                        if (decode) {
                            if (decode.correct == true) {
                                point = item_answer.question_point
                                setCheckChoice(true)
                                setTextStatus(<div className="col-12 rounded pt-1 pb-1 bg-success text-white">Chính xác: {decode.explanation}</div>)
                            } else {
                                setCheckChoice(true)
                                setTextStatus(<div className="col-12 rounded pt-1 pb-1" style={{ backgroundColor: "rgba(255,223,224)" }}>
                                    <h4 className="w-100 text-center text-danger">Sai rồi</h4>
                                    <div className="row">
                                        <div className="col-12 col-sm-2"><strong>Giải thích:</strong></div>
                                        <div className="col-12 col-sm-10" style={{ whiteSpace: "pre-wrap" }}>
                                            {decode.explanation}
                                        </div>
                                    </div>
                                </div>)
                                point = 0
                            }
                            if (!answers) {
                                saveStudentAnswer(item_answer.id, item_answer.id_student_answer, point, decode.explanation)
                                setOnAnswer(snschoice);
                            }
                        }
                    } catch (parseError) {
                        console.error("Error parsing JSON:", parseError);
                    }
                }
            } else {
                if (point > 0) {
                    setCheckChoice(true)
                    setTextStatus(<div className="col-12 rounded pt-1 pb-1 bg-success text-white">Chính xác</div>)
                } else {
                    if (s_answers.question.code !== "QChooseOne" && s_answers.question.code !== "QFillTo") {
                        text_ans = <GenderCode text_string={text_ans} />;
                    }

                    if (s_answers.question.code === "QDropDrag") {
                        text_ans = question_answers.map((itQ, i) => {
                            return (<div className="col-auto pl-0 pr-0 mt-1 d-flex align-items-center" key={i}>
                                <div className="py-1 px-2 cursor-pointer rounded border-grey bg-white">
                                    {itQ.includes("<class") && itQ.includes(">") ? itQ : parse(itQ)}
                                </div>
                                {i + 1 < question_answers.length && <i className="fas fa-arrow-right" style={{ fontSize: 11 }}></i>}
                            </div>)
                        })
                    }
                    if (s_answers.question.code === "QChooseMulti") {
                        text_ans = question_answers.map((itQ, i) => {
                            let answ = Array.isArray(s_answers.question.answers) ? s_answers.question.answers : JSON.parse(s_answers.question.answers)
                            return (<div className="col-6 mt-1" key={i}>{array_alpha[itQ]}. <button type="button" className="btn btn-sm btn-secondary"><GenderCode text_string={answ[itQ]} /></button></div>)
                        })
                    }
                    setCheckChoice(true)
                    setTextStatus(<div className="col-12 rounded pt-1 pb-1" style={{ backgroundColor: "rgba(255,223,224)" }}>
                        <h4 className="w-100 text-center text-danger">Sai rồi</h4>
                        <div className="row">
                            <div className="col-12 col-sm-2"><strong>Đáp án đúng là:</strong></div>
                            <div className="col-12 col-sm-10" style={{ whiteSpace: "pre-wrap" }}>
                                {s_answers.question.code !== "QDropDrag" && s_answers.question.code !== "QFillTo"
                                    ?
                                    <div>{text_ans}</div>
                                    :
                                    <div className="row"> {text_ans}</div>}
                            </div>
                        </div>
                    </div>)
                }
                if (!answers) {
                    saveStudentAnswer(item_answer.id, item_answer.id_student_answer, point)
                    setOnAnswer(snschoice);
                }
            }

        }
    }

    const saveStudentAnswer = (qid, id_student_answer, point, comments = null, type = null) => {
        let datasave = {}
        if (type) {

            datasave = {
                student_id: user.data.id,
                test_id: detail.id,
                question_id: qid,
                point: point,
                corrects: null,
                status: 1,
                comments: comments
            }
        } else {
            let student_answers = Answers && Answers[qid] && Answers[qid].answers ? Answers[qid].answers : null
            if(Answers[qid].answers == 0 ){
                student_answers = 0
            }
            datasave = {
                student_id: user.data.id,
                test_id: detail.id,
                question_id: qid,
                point: point,
                corrects: JSON.stringify(student_answers),
                status: 1,
                comments: comments
            }
        }
        Axios.put(URL_LIST_ALL + "at_exam_students_answers/" + id_student_answer, datasave, user.config).then(async (res) => {
            setResetPoint(!resetPoint)
        }).catch((err) => { console.log(err); });
    }

    useEffect(() => {
        if (listQuestion && listQuestion[indexQuestion]) {
            let currenQuestion = listQuestion[indexQuestion]
            if (currenQuestion.students_answers) {
                checkQuestion(indexQuestion, { answers: currenQuestion.students_answers, question: currenQuestion })

            }
        }
    }, [listQuestion])

    const onFinish = () => {
        Axios.post(URL_LIST_ALL + "on_finish_racing", { category_id: detail.category_info.id, user_id: user.data.id }, user.config).then(async (res) => {
            if (res.data.status === 'success') {
                setRefresh(!refresh)
            }
        }).catch((err) => { console.log(err); });
    }

    const onNextQuestion = () => {
        let clone_list = [...listQuestion]
        let check = 1
        let clone_ques = { ...clone_list[indexQuestion] }
        clone_ques.answer_status = 1
        clone_list[indexQuestion] = clone_ques

        setListQuestion(clone_list)
        clone_list.forEach((item, i) => {
            if (item.answer_status != 1 && check == 1 && i !== indexQuestion) {
                check = 0
                setIndexQuestion(i)
                setCheckChoice(false)
                setTextStatus("")
            }
        });
        if (check === 1) {
            setRefresh(!refresh)
        }
    }
    return (
        <AkkhorLayout idrole="examination" roleignone={user.data.typeaccount === 2}>
            {detail && (
                <div className="container-fluid p-0">
                    <div className="breadcrumbs-area py-1 py-md-1">
                        <ul>
                            <li>
                                <Link to="/">Trang chủ</Link>
                            </li>
                            <li>Đường đua Tomo <i className="fas fa-flag-checkered"></i></li>
                        </ul>
                    </div>
                    <div className="card height-auto card-question">
                        <div className="card-body">
                            <div className="w-100 minheight-500">
                                <div className="row">
                                    <div className="col-12">

                                        <div className="mb-2 border minheight-500 rounded position-relative overflow-hidden " id={"nuques-" + indexQuestion}>
                                            <h2 className="race-title text-center py-2">
                                                {detail && detail.category_info && detail.category_info.title}
                                                <div className="total-point">
                                                    <div className="wrapper">
                                                        <span>{totalPoint} <br />điểm</span>
                                                    </div>
                                                </div>
                                            </h2>
                                            {listQuestion.length > 0 && listQuestion[indexQuestion] ?
                                                <>
                                                    <div className="w-100 pl-3 pr-3 mt-3 position-relative">
                                                        <div className="w-100 outline">
                                                            <div className="bg-success line-head text-center" style={{ color: "#FFF", fontSize: 11, height: 16, width: (finish ? 100 : ((indexQuestion) / detail.total) * 100) + "%" }}>
                                                                {(finish ? 100 : (((indexQuestion) / detail.total) * 100).toFixed(0)) + "%"}
                                                            </div>
                                                        </div>
                                                        <span className="fas fa-running icon-running" style={{ left: (finish ? 100 : ((indexQuestion) / detail.total) * 100) + "%" }}></span>
                                                        <span className="fas fa-flag-checkered icon-running" style={{ right: 0 }}></span>
                                                    </div>
                                                    <div className="w-100 text-center  mb-3">
                                                        {indexQuestion + 1}/{detail && detail.total} Câu hỏi
                                                    </div>
                                                    {!finish ?
                                                        <>
                                                            <div className="h-400 question-choisce">
                                                                <div className="font-weight-bold p-2 bg-info text-light d-flex justify-content-between">
                                                                    <div>Câu hỏi {indexQuestion + 1}</div>
                                                                    <div> {listQuestion[indexQuestion].question_point} điểm</div>
                                                                </div>
                                                                {listQuestion[indexQuestion].parent_title && <div className="p-2 border-bottom">
                                                                    <GenderCode text_string={listQuestion[indexQuestion].parent_title} />
                                                                </div>}
                                                                <div className="p-3 title-question">
                                                                    <GenderCode text_string={listQuestion[indexQuestion].title} />
                                                                </div>
                                                                <div>
                                                                    <SingleChoiceQuestion
                                                                        key={indexQuestion}
                                                                        index={indexQuestion}
                                                                        item={listQuestion[indexQuestion]}
                                                                        test={''}
                                                                        onAnswer={onAnswer}
                                                                    ></SingleChoiceQuestion>
                                                                </div>
                                                                <div className="w-100 d-block d-sm-none mt-2">
                                                                    {textStatus}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between p-2 action-race">
                                                                <div> <button className="btn btn-warning" onClick={() => {
                                                                    saveStudentAnswer(indexQuestion, listQuestion[indexQuestion].id_student_answer, 0, 1)
                                                                    onNextQuestion()
                                                                }} >Bỏ qua</button></div>
                                                                <div className="w-100 d-none d-sm-block text-result pl-3 pr-3 ">
                                                                    {textStatus}
                                                                </div>
                                                                <div>
                                                                    {checkChoice || (listQuestion[indexQuestion].students_answers) ?
                                                                        <>
                                                                            {indexQuestion < detail.total - 1 ?
                                                                                <button className="btn btn-success" onClick={() => {
                                                                                    if (indexQuestion < detail.total - 1) {
                                                                                        onNextQuestion()
                                                                                    }
                                                                                }} >Tiếp tục</button>
                                                                                :
                                                                                <button className="btn btn-success" onClick={() => { onFinish() }} >Kết thúc</button>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <button className="btn btn-info" onClick={() => {
                                                                            checkQuestion(listQuestion[indexQuestion], null)
                                                                        }}>Kiểm tra {checking ? <Spinner /> : ''}</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <h3 className="w-100 text-center mt-5">Chúc mừng bạn đã vượt qua phần thi: {detail.category_info.title}</h3>
                                                            <h4 className="w-100 text-center mt-2">Số điểm đạt được: {totalPoint}</h4>
                                                            <div className="w-100 text-center mt-3">
                                                                <a className="btn btn-sm btn-success mr-2" href={"/tomo-racing-view/" + detail.category_info.id}><i className="fas fa-angle-double-left"></i> <strong>Xem kết quả</strong> <span className="fas fa-history"></span></a>
                                                                <a className="btn btn-sm btn-info" href={"/tomo-racing-view/" + detail.category_info.id}><span className="fab fa-google-play"></span> <strong>Tiếp tục chơi</strong> <i className="fas fa-angle-double-right"></i></a>
                                                                {/* <a className="text-danger" href="/tomo-racing"><span className="fas fa-flag-checkered"></span> <strong>Trở về đường đua</strong> <i className="fas fa-angle-double-right"></i></a> */}
                                                            </div>
                                                        </>
                                                    }
                                                </> :
                                                <h4 className="w-100 text-center p-3 mt-3 text-danger border-top">Chặng đua chưa bắt đầu</h4>
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {listQuestion && detail && (indexQuestion != null || indexQuestion == 0) ?
                        <div className="card height-auto card-question mt-2">
                            <div className="card-body">
                                {listQuestion[indexQuestion] && listQuestion[indexQuestion].id ? <FeedBackComment question_id={listQuestion[indexQuestion].id} test_id={detail.id} exam_student={examStudent} /> : ''}
                            </div>
                        </div>
                        : ''
                    } */}
                </div>
            )}
        </AkkhorLayout>
    );
}

